export const menuItems = [
  {
    name: "",
    label: "Home",
  },
  {
    name: "talent-list",
    label: "Talents",
  },
  {
    name: "companies",
    label: "Companies",
  },
  {
    name: "search-list",
    label: "Jobs",
  },
  // {
  //   name: "https://neartasks.netlify.app",
  //   label: "Tasks",
  //   isExternal: true,
  // },
  {
    name: "contact-us",
    label: "Contact Us",
  },
];
