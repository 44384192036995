const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN_USER':
      // console.log('REDUCER: ', action.payload);
      const profileView = action.payload?.client ?
        "Client" :
        action.payload?.talent ?
          "Talent" :
          action.payload?.client && action.payload?.talent ?
            "Dual" :
            action.payload?.admin ?
              "Admin" :
              null;
      // console.log('profileView: ', profileView);
      return {
        ...state,
        auth: action.payload,
        profileView,
      };
    case 'LOGOUT_USER':
      return {
        ...state,
        auth: null,
      };
    case 'GET_AUTH':
      return {
        ...state,
        auth: action.payload,
      };
    default:
      throw new Error();
  }
};

export default authReducer;
