import {
  query,
  collection,
  getDocs,
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  arrayUnion,
  arrayRemove,
  where,
  deleteField,
} from "firebase/firestore";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { db, storage } from "../services/firebase";

// STORAGE
const logosRef = ref(storage, "logos/");
const imagesRef = ref(storage, "images/");
const blogRef = ref(storage, "blog/");

// Save Talents
export const saveTalents = async (accountId, data) => {
  try {
    const talentsCollectionRef = collection(db, "savedTalents");
    const response = await setDoc(
      doc(db, talentsCollectionRef.id, accountId),
      {
        created: serverTimestamp(),
        createdBy: accountId,
        talents: arrayUnion(data),
      },
      { merge: true }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchSavedTalents = async (accountId) => {
  try {
    const querySnapshot = await getDocs(collection(db, "savedTalents"));
    const talents = querySnapshot.docs;
    const savedTalents = talents.filter((doc) => {
      if (doc.id === accountId) {
        if (doc.exists()) {
          return doc.data();
        }
      }
    });
    return savedTalents[0]?.data() || null;
  } catch (error) {
    console.log(error);
  }
};

// Check if account and profile exists already
const profileExists = async (accountId, accountType) => {
  const profile = accountType.toLowerCase();
  const users = await getDocs(collection(db, "users"));
  const response = users.docs.filter((account) => {
    if (account.id === accountId) {
      if (profile in account.data()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  return response;
};

// Create User Acct
export const createAccount = async (accountId, accountType, data) => {
  try {
    const usersCollectionRef = collection(db, "users");
    // Client
    if (accountType === "Client") {
      // Check if profile is already created
      const profile = await profileExists(accountId, accountType);
      if (profile.length > 0) {
        return false;
      } else {
        await setDoc(doc(db, usersCollectionRef.id, accountId), {
          client: {
            created: serverTimestamp(),
            status: "active",
            ...data,
          },
        });
        return true;
      }
    } else {
      // Talent
      // Check if profile is already created
      const profile = await profileExists(accountId, accountType);
      if (profile.length > 0) {
        return false;
      } else {
        await setDoc(doc(db, usersCollectionRef.id, accountId), {
          talent: {
            created: serverTimestamp(),
            status: "active",
            workExperience: [],
            ...data,
          },
        });
        return true;
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchUserAccount = async (accountId) => {
  try {
    const querySnapshot = await getDocs(collection(db, "users"));

    const users = querySnapshot.docs;
    const data = users.filter((doc) => {
      if (doc.id === accountId) {
        const user = doc.data();
        return user;
      } else {
        return null;
      }
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Update Acct
export const updateAccount = async (accountId, accountType, data) => {
  try {
    const usersCollectionRef = collection(db, "users");
    if (accountType === "Client") {
      await updateDoc(
        doc(db, usersCollectionRef.id, accountId),
        {
          client: {
            updated: serverTimestamp(),
            ...data,
          },
        },
        { merge: true }
      );
      return true;
    } else if (accountType == "Talent XP") {
      await updateDoc(
        doc(db, usersCollectionRef.id, accountId),
        {
          talent: {
            updated: serverTimestamp(),
            ...data,
          },
        },
        { merge: true }
      );
      return true;
    } else {
      await updateDoc(
        doc(db, usersCollectionRef.id, accountId),
        {
          talent: {
            updated: serverTimestamp(),
            ...data,
          },
        },
        { merge: true }
      );
      return true;
    }
  } catch (error) {
    console.log(error);
  }
};

// Fetch client's jobs sorted by last posted
export const fetchClientJobs = async (accountId) => {
  let result = [];
  let querySnapshot = query(
    collection(db, "jobs"),
    where("client", "==", accountId)
  );
  const myJobs = await getDocs(querySnapshot);
  const userJobs = myJobs.docs.filter((job) => {
    if (job.exists()) {
      result.push(job.data());
    }
  });
  // Sort by id - asc
  // result.sort((a, b) => (b.id - a.id));
  // Sort by created - desc
  result.sort(
    (a, b) =>
      new Date(b.created.toDate()).getTime() -
      new Date(a.created.toDate()).getTime()
  );
  return result;
};

// Fetch job details
export const fetchJobDetails = async (jobId) => {
  try {
    let querySnapshot = query(collection(db, "jobs"), where("id", "==", jobId));
    const data = await getDocs(querySnapshot);
    let response = data.docs.length > 0 ? data.docs[0].data() : null;
    return response;
  } catch (error) {
    console.log(error);
  }
};

// Fetch all the jobs
export const fetchAllJobs = async () => {
  let result = [];
  let querySnapshot = query(collection(db, "jobs"));
  const jobs = await getDocs(querySnapshot);
  const userJobs = jobs.docs.filter((job) => {
    if (job.exists()) {
      result.push(job.data());
    }
  });
  // result.sort((a, b) => (b.id - a.id));
  result.sort(
    (a, b) =>
      new Date(b.created.toDate()).getTime() -
      new Date(a.created.toDate()).getTime()
  );
  return result;
};

// Fetch all the talents
export const fetchAllTalents = async () => {
  let result = [];
  let querySnapshot = query(collection(db, "users"));
  const talents = await getDocs(querySnapshot);
  const userJobs = talents.docs.filter((talent) => {
    if (talent.exists() && talent.data()?.talent) {
      result.push(talent.data()?.talent);
    }
  });
  result.sort((a, b) => b.created - a.created);
  return result;
};

// Create a job
export const createJob = async (accountId, data) => {
  try {
    const newJobRef = doc(collection(db, "jobs"));
    await setDoc(newJobRef, {
      id: newJobRef.id,
      client: accountId,
      bids: [],
      created: serverTimestamp(),
      payment: "pending",
      status: "pending",
      ...data,
    });
    return newJobRef.id;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Apply to job (update job)
export const updateJob = async (jobId, updateType, data) => {
  try {
    const jobsRef = collection(db, "jobs");
    // Check if talent has already applied to job
    let querySnapshot = query(collection(db, "jobs"), where("id", "==", jobId));
    const jobs = await getDocs(querySnapshot);
    let jobData = jobs.docs.length > 0 ? jobs.docs[0].data() : null;
    if (jobData) {
      if (updateType === "application") {
        const applicationExists = jobData.bids.find(
          (bid) => bid.account_id === data.account_id
        );
        if (
          applicationExists &&
          applicationExists.account_id === data.account_id
        ) {
          throw new Error("You have already applied to this job");
        } else {
          // const jobsRef = collection(db, "jobs");
          await updateDoc(
            doc(db, jobsRef.id, jobId),
            {
              bids: arrayUnion(data),
            },
            { merge: true }
          );
          return true;
        }
      } else if (updateType === "transaction") {
        await updateDoc(
          doc(db, jobsRef.id, jobId),
          {
            txhash: data,
          },
          { merge: true }
        );
        return true;
      } else if (updateType === "admin") {
        await updateDoc(
          doc(db, jobsRef.id, jobId),
          {
            ...data,
          },
          { merge: true }
        );
        return true;
      } else if (updateType === "delete") {
        await updateDoc(
          doc(db, jobsRef.id, jobId),
          {
            ...data,
          },
          { merge: true }
        );
        return true;
      } else if (updateType === "client") {
        await updateDoc(
          doc(db, jobsRef.id, jobId),
          {
            ...data,
          },
          { merge: true }
        );
        return true;
      } else if (updateType === "contract") {
        await updateDoc(
          doc(db, jobsRef.id, jobId),
          {
            ...data,
          },
          { merge: true }
        );
        return true;
      }
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Fetch all the client
export const fetchAllClients = async () => {
  let result = [];
  let querySnapshot = query(collection(db, "users"));
  const clients = await getDocs(querySnapshot);
  const data = clients.docs.filter((client) => {
    if (client.exists() && client.data()?.client) {
      result.push(client.data()?.client);
    }
  });
  result.sort((a, b) => b.created - a.created);
  return result;
};

// Fetch all the contracts
export const fetchAllContracts = async () => {
  let result = [];
  let querySnapshot = query(collection(db, "contracts"));
  const contracts = await getDocs(querySnapshot);
  const data = contracts.docs.filter((contract) => {
    if (contract.exists()) {
      result.push(contract.data());
    }
  });
  // result.sort((a, b) => (b.created - a.created));
  result.sort(
    (a, b) =>
      new Date(b.created.toDate()).getTime() -
      new Date(a.created.toDate()).getTime()
  );
  return result;
};

// Get Stats
export const getStats = async () => {
  try {
    const jobs = await fetchAllJobs();
    const talents = await fetchAllTalents();
    const clients = await fetchAllClients();
    const contracts = await fetchAllContracts();
    const result = {
      jobs: jobs,
      talents: talents,
      clients: clients,
      contracts: contracts,
    };
    // console.log("RESULT: ", result);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Enable/Disable user
export const updateUserStatus = async (accountId, accountType, data) => {
  try {
    const usersCollectionRef = collection(db, "users");
    if (accountType === "Client") {
      await updateDoc(
        doc(db, usersCollectionRef.id, accountId),
        {
          "client.updated": serverTimestamp(),
          "client.status": data.status,
        },
        { merge: true }
      );
      return true;
    } else {
      await updateDoc(
        doc(db, usersCollectionRef.id, accountId),
        {
          "talent.updated": serverTimestamp(),
          "talent.status": data.status,
        },
        { merge: true }
      );
      return true;
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Set revenue model
export const updateRevenueModel = async (data) => {
  try {
    const revenueModelRef = doc(
      db,
      collection(db, "revenueModel").id,
      "settings"
    );
    await setDoc(
      revenueModelRef,
      {
        ...data,
      },
      { merge: true }
    );
    return true;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Fetch revenue model
export const fetchRevenueModel = async () => {
  try {
    let querySnapshot = query(collection(db, "revenueModel"));
    const data = await getDocs(querySnapshot);
    let response = data.docs.length > 0 ? data.docs[0].data() : null;
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Hire Talent
export const hireTalent = async (data) => {
  try {
    const newContractRef = doc(collection(db, "contracts"));
    await setDoc(newContractRef, {
      id: newContractRef.id,
      created: serverTimestamp(),
      status: "pending",
      ...data,
    });
    return newContractRef.id;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Fetch user's contracts
export const fetchUserContracts = async (accountId, accountType) => {
  let result = [];
  let querySnapshot = query(
    collection(db, "contracts"),
    where(accountType, "==", accountId)
  );
  const myContracts = await getDocs(querySnapshot);
  const userJobs = myContracts.docs.filter((contract) => {
    if (contract.exists()) {
      result.push(contract.data());
    }
  });
  // result.sort((a, b) => (b.id - a.id)); // Sort by id - desc
  result.sort(
    (a, b) =>
      new Date(b.created.toDate()).getTime() -
      new Date(a.created.toDate()).getTime()
  ); // Sort by last created
  return result;
};

// Update Contract
export const updateContract = async (contractId, data) => {
  try {
    const contractsRef = collection(db, "contracts");
    // Check if talent has already applied to job
    let querySnapshot = query(
      collection(db, "contracts"),
      where("id", "==", contractId)
    );
    const contracts = await getDocs(querySnapshot);
    let contractData =
      contracts.docs.length > 0 ? contracts.docs[0].data() : null;
    if (contractData) {
      await updateDoc(
        doc(db, contractsRef.id, contractId),
        {
          ...data,
        },
        { merge: true }
      );
      return true;
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Fetch contract details
export const fetchContractDetails = async (contractId) => {
  try {
    let querySnapshot = query(
      collection(db, "contracts"),
      where("id", "==", contractId)
    );
    const data = await getDocs(querySnapshot);
    let response = data.docs.length > 0 ? data.docs[0].data() : null;
    return response;
  } catch (error) {
    console.log(error);
  }
};

// Fetch Talent's jobs
export const fetchTalentJobs = async (accountId) => {
  try {
    let result = [];
    let querySnapshot = query(collection(db, "jobs"));
    const myJobs = await getDocs(querySnapshot);
    const userJobs = myJobs.docs.filter((job) => {
      if (job.exists()) {
        job.data().bids.filter((bid) => {
          if (bid.account_id === accountId) {
            result.push(job.data());
          }
        });
      }
    });
    result.sort((a, b) => b.id - a.id);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Add new category
export const addCategory = async (data) => {
  try {
    const categoryRef = doc(db, collection(db, "settings").id, "categories");
    await setDoc(
      categoryRef,
      {
        categories: arrayUnion(data),
      },
      { merge: true }
    );
    return true;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Fetch categories and nested subcategories
export const fetchCategories = async () => {
  try {
    let querySnapshot = query(collection(db, "settings"));
    const data = await getDocs(querySnapshot);
    let response = data.docs.length > 0 ? data.docs[0].data().categories : null;
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Add new subcategory
export const addSubCategory = async (categoryname, data) => {
  try {
    const categoryRef = collection(db, "settings");
    let querySnapshot = query(collection(db, "settings"));
    const categories = await getDocs(querySnapshot);
    let response =
      categories.docs.length > 0
        ? categories.docs[0]
            .data()
            .categories.filter(({ label }) => label === categoryname)
        : [];
    const index = categories.docs[0]
      .data()
      .categories.findIndex(({ label }) => label === categoryname);
    const record = categories.docs[0].get("categories")[index];
    // // Clear existing category
    await updateDoc(doc(db, categoryRef.id, "categories"), {
      categories: arrayRemove(response[0]),
    });
    const newRecord = record.subcategories.push(data);
    await updateDoc(doc(db, categoryRef.id, "categories"), {
      categories: arrayUnion(record),
    });
    return true;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Set profile to featured
export const setProfileFeatured = async (accountId) => {
  try {
    const usersCollectionRef = collection(db, "users");
    await updateDoc(
      doc(db, usersCollectionRef.id, accountId),
      {
        "talent.featured": true,
        "talent.updated": serverTimestamp(),
      },
      { merge: true }
    );
    return true;
  } catch (error) {
    console.log(error);
  }
};

// Set profile to unfeatured
export const revertProfileFeatured = async (accountId) => {
  try {
    const usersCollectionRef = collection(db, "users");
    await updateDoc(
      doc(db, usersCollectionRef.id, accountId),
      {
        "talent.featured": false,
        "talent.updated": serverTimestamp(),
      },
      { merge: true }
    );
    return true;
  } catch (error) {
    console.log(error);
  }
};


//Get account image (company logo or profile picture)
export const getAccountImage = async (id) => {
  try {
    let url;
    const allLogos = await listAll(logosRef);
    const allImages = await listAll(imagesRef);
    const allBlogs = await listAll(blogRef);

    const logoData = allLogos.items.filter((logo) => logo.name === id);
    if (logoData.length > 0) {
      url = await getDownloadURL(logoData[0]);
    }

    const imageData = allImages.items.filter((image) => image.name === id);
    if (imageData.length > 0) {
      url = await getDownloadURL(imageData[0]);
    }

    const blogData = allBlogs.items.filter((image) => image.name === id);
    if (blogData.length > 0) {
      url = await getDownloadURL(blogData[0]);
    }
    return url;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Create a work experience
export const AddWorkExperience = async (accountId, data) => {
  try {
    const workExperienceRef = collection(
      db,
      `workExperiences/${accountId}/workExperience`
    );
    const querySnapshot = await getDocs(workExperienceRef);
    const workExperiences = querySnapshot.size;
    const workExperiencesCount = workExperiences == 0 ? 1 : workExperiences + 1;

    await setDoc(
      doc(
        db,
        `workExperiences/${accountId}/workExperience/${workExperiencesCount}`
      ),
      {
        id: `${workExperiencesCount}`,
        ...data,
      }
    );

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// Fetch user's contracts
export const fetchWorkExperience = async (accountId) => {
  let result = [];
  let querySnapshot = query(
    collection(db, `workExperiences/${accountId}/workExperience`)
  );
  const workExperiences = await getDocs(querySnapshot);
  const workExperience = workExperiences.docs.filter((experience) => {
    if (experience.exists()) {
      result.push(experience.data());
    }
  });

  console.log(result);
  return result;
};

// Create a blog
export const createBlog = async (id, data) => {
  try {
    const newBlogRef = doc(collection(db, "blogs"));
    await setDoc(newBlogRef, {
      id: newBlogRef.id,
      created: serverTimestamp(),
      ...data,
    });
    return newBlogRef.id;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Fetch blog list
export const fetchBlogList = async () => {
  let result = [];
  let querySnapshot = query(collection(db, "blogs"));
  const blogs = await getDocs(querySnapshot);
  const data = blogs.docs.filter((blog) => {
    if (blog.exists() && blog.data()) {
      result.push(blog.data());
    }
  });
  result.sort((a, b) => b.created - a.created);
  return result;
};

// Fetch blog details
export const fetchBlogDetails = async (blogId) => {
  try {
    let querySnapshot = query(
      collection(db, "blogs"),
      where("blogId", "==", blogId)
    );
    const data = await getDocs(querySnapshot);
    let response = data.docs.length > 0 ? data.docs[0].data() : null;
    return response;
  } catch (error) {
    console.log(error);
  }
};

// Update blog
export const updateBlog = async (blogId, data) => {
  try {
    const blogsRef = collection(db, "blogs");
    let querySnapshot = query(
      collection(db, "blogs"),
      where("id", "==", blogId)
    );
    const blogs = await getDocs(querySnapshot);
    let blogData = blogs.docs.length > 0 ? blogs.docs[0].data() : null;
    if (blogData) {
      await updateDoc(
        doc(db, blogsRef.id, blogId),
        {
          ...data,
        },
        { merge: true }
      );
      return true;
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};


// Create a blog
export const storeContactUsDetails = async (data) => {
  try {
    const newDetailsRef = doc(collection(db, "contactUsDetails"));
    await setDoc(newDetailsRef , {
      id: newDetailsRef .id,
      created: serverTimestamp(),
      ...data,
    });
    return true
  } catch (error) {
    console.log(error);
    return null;
  }
};
