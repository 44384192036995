import { connect, keyStores, WalletConnection, utils } from "near-api-js";
import getConfig from "./config";

let nearConfig;

export async function logout() {
  await window.walletConnection.signOut();
}

export async function login(currentUrl, redirectUrl) {
  return await window.walletConnection.requestSignIn({
    contractId: nearConfig.contractName,
    title: "DevShop",
    successUrl: `${process.env.GATSBY_SITE_URL}${redirectUrl}`,
    failureUrl: `${process.env.GATSBY_SITE_URL}${currentUrl}`,
  });
}

// Get user wallet balance
export async function getBalance(accountId) {
  try {
    const keyStore = new keyStores.BrowserLocalStorageKeyStore();
    nearConfig = getConfig(
      process.env.GATSBY_NETWORK_ID || "testnet",
      keyStore
    );
    const nearConnection = await connect(nearConfig);
    window.walletConnection = new WalletConnection(nearConnection, "DevShop");
    const account = await nearConnection.account(accountId);
    const balance = await account.getAccountBalance();
    const total = utils.format.formatNearAmount(balance.total, 5);
    const stateStaked = utils.format.formatNearAmount(balance.stateStaked, 5);
    const staked = utils.format.formatNearAmount(balance.staked, 5);
    const available = utils.format.formatNearAmount(balance.available, 5);
    const wallet = {
      total,
      stateStaked,
      staked,
      available,
    };
    return wallet;
  } catch (error) {
    return error;
  }
}
