import React, {
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";

import styled, { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";
import AOS from "aos";
import TimeAgo from "javascript-time-ago";
import { Toaster } from "react-hot-toast";
import {
  setPersistence,
  signInWithEmailAndPassword,
  browserLocalPersistence,
} from "firebase/auth";
import { firebaseAuth } from "../../services/firebase";

import Header from "../Header";
import Footer from "../Footer";

import SidebarDashboard from "../SidebarDashboard";

import GlobalContext from "../../context/GlobalContext";
import { DataContext } from "../../context/DataContext";

import GlobalStyle from "../../utils/globalStyle";

import imgFavicon from "../../assets/favicon.png";
import imgFaviconApple from "../../assets/apple-touch-icon.png";
import imgFavicon32 from "../../assets/favicon-32x32.png";
import imgFavicon16 from "../../assets/favicon-16x16.png";

import "../../assets/fonts/fontawesome-5/webfonts/fa-brands-400.ttf";
import "../../assets/fonts/fontawesome-5/webfonts/fa-regular-400.ttf";
import "../../assets/fonts/fontawesome-5/webfonts/fa-solid-900.ttf";

import "../../assets/fonts/icon-font/fonts/avasta.ttf";
import "../../assets/fonts/icon-font/css/style.css";

import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "../../../node_modules/aos/dist/aos.css";

import "../../assets/fonts/icon-font/css/style.css";
import "../../assets/fonts/fontawesome-5/css/all.css";

import "../../scss/bootstrap.scss";
import "../../scss/main.scss";
import "../../../node_modules/react-loading-skeleton/dist/skeleton.css";
import "@near-wallet-selector/modal-ui/styles.css";
import en from "../../../node_modules/javascript-time-ago/locale/en.json";



import { get, merge } from "lodash";

// the full theme object
import { theme as baseTheme } from "../../utils";

TimeAgo.addDefaultLocale(en);

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999999999;
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-out 0.5s;
  &.inActive {
    opacity: 0;
    visibility: hidden;
  }
`;

// options for different color modes
const modes = { light: "light", dark: "dark" };

// merge the color mode with the base theme
// to create a new theme object
const getTheme = (mode) =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
  });

const Layout = ({ children, pageContext }) => {
  const gContext = useContext(GlobalContext);
  const { getCategories, getRevenueModel, getSkills } = useContext(DataContext);

  const [visibleLoader, setVisibleLoader] = useState(true);

  const loadSettings = async () => {
    await getRevenueModel();
    await getCategories();
    await getSkills();
  };

  const firebaseLogin = async () => {
    try {
      await setPersistence(firebaseAuth, browserLocalPersistence);
      const res = await signInWithEmailAndPassword(
        firebaseAuth,
        process.env.GATSBY_FIREBASE_AUTH_EMAIL,
        process.env.GATSBY_FIREBASE_AUTH_PASSWORD
      );
      if (res?.user?.uid) {
        await loadSettings();
      }
    } catch (error) {
      console.log(error.code, error.message);
    }
  };

  // Navbar style based on scroll
  const eleRef = useRef();

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      if (!user) {
        firebaseLogin();
      } else {
        loadSettings();
      }
    });
    AOS.init({ once: true });
    setVisibleLoader(false);

    window.addEventListener(
      "popstate",
      function (event) {
        // The popstate event is fired each time when the current history entry changes.
        gContext.closeOffCanvas();
      },
      false
    );
    window.addEventListener(
      "pushState",
      function (event) {
        // The pushstate event is fired each time when the current history entry changes.
        gContext.closeOffCanvas();
      },
      false
    );

  }, [gContext]);

  if (pageContext.layout === "bare") {
    return (
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <div data-theme-mode-panel-active data-theme="light">
          <GlobalStyle />
          <Helmet>
            <title>
              DevShop - Connecting Africa's top talents with the world's leading
              companies
            </title>
            <link rel="icon" type="image/png" href={imgFavicon} />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href={imgFaviconApple}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href={imgFavicon32}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href={imgFavicon16}
            />
            <meta
              name="google-site-verification"
              content="yM6Wgk1vyHE4PBzOfsdzLXhmVFJzp32s8PC2e7fGfv0"
            />
          </Helmet>
          <Loader id="loading" className={visibleLoader ? "" : "inActive"}>
            <div className="load-circle">
              <span className="one"></span>
            </div>
          </Loader>
          <div className="site-wrapper overflow-hidden" ref={eleRef}>
            {children}
          </div>
          <Toaster />
        </div>
      </ThemeProvider>
    );
  }

  if (pageContext.layout === "dashboard") {
    return (
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <div data-theme-mode-panel-active data-theme="light">
          <GlobalStyle />
          <Helmet>
            <title>
              DevShop - Connecting Africa's top talents with the world's leading
              companies
            </title>
            <link rel="icon" type="image/png" href={imgFavicon} />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href={imgFaviconApple}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href={imgFavicon32}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href={imgFavicon16}
            />
            <meta
              name="google-site-verification"
              content="yM6Wgk1vyHE4PBzOfsdzLXhmVFJzp32s8PC2e7fGfv0"
            />
          </Helmet>
          <Loader id="loading" className={visibleLoader ? "" : "inActive"}>
            <div className="load-circle">
              <span className="one"></span>
            </div>
          </Loader>
          <div
            className="site-wrapper overflow-hidden bg-default-2"
            ref={eleRef}
          >
            <Header isDark={gContext.headerDark} />
            <SidebarDashboard />
            {children}
          </div>
          <Toaster />
        </div>
      </ThemeProvider>
    );
  }

  return (
    <>
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <div data-theme-mode-panel-active data-theme="light">
          <GlobalStyle />
          <Helmet>
            <title>
              DevShop - Connecting Africa's top talents with the world's leading
              companies
            </title>
            <link rel="icon" type="image/png" href={imgFavicon} />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href={imgFaviconApple}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href={imgFavicon32}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href={imgFavicon16}
            />
            <meta
              name="google-site-verification"
              content="yM6Wgk1vyHE4PBzOfsdzLXhmVFJzp32s8PC2e7fGfv0"
            />
          </Helmet>
          <Loader id="loading" className={visibleLoader ? "" : "inActive"} />
          <div className="site-wrapper overflow-hidden" ref={eleRef}>
            <Header isDark={gContext.headerDark} />
            {children}

            <Footer isDark={gContext.footerDark} />
          </div>
          <Toaster />
        </div>
      </ThemeProvider>
    </>
  );
};

export default Layout;
