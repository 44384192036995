import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  createContext
} from "react";
import { navigate } from "gatsby";
import { setupWalletSelector } from "@near-wallet-selector/core";
import { setupModal } from "@near-wallet-selector/modal-ui";
import { setupNearWallet } from "@near-wallet-selector/near-wallet";
import { setupSender } from "@near-wallet-selector/sender";
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import { distinctUntilChanged, map } from "rxjs";

import { setupHereWallet } from "@near-wallet-selector/here-wallet";
import { setupMathWallet } from "@near-wallet-selector/math-wallet";
import { setupNightly } from "@near-wallet-selector/nightly";
import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet";
import { setupNarwallets } from "@near-wallet-selector/narwallets";
import { setupWelldoneWallet } from "@near-wallet-selector/welldone-wallet";
import { setupNearSnap } from "@near-wallet-selector/near-snap";
import { setupLedger } from "@near-wallet-selector/ledger";
import { setupWalletConnect } from "@near-wallet-selector/wallet-connect";
import { setupNightlyConnect } from "@near-wallet-selector/nightly-connect";
import { setupNearFi } from "@near-wallet-selector/nearfi";
import { setupCoin98Wallet } from "@near-wallet-selector/coin98-wallet";
import { setupOptoWallet } from "@near-wallet-selector/opto-wallet";
import { setupFinerWallet } from "@near-wallet-selector/finer-wallet";
import { setupNeth } from "@near-wallet-selector/neth";
import { setupXDEFI } from "@near-wallet-selector/xdefi";

const WalletSelectorContext = createContext(null);

export const WalletSelectorContextProvider = ({ children }) => {

  const [selector, setSelector] = useState(null);
  const [modal, setModal] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);

  const init = useCallback(async () => {
    const _selector = await setupWalletSelector({
      network: process.env.GATSBY_NETWORK_ID,
      debug: false,
      modules: [
        setupMyNearWallet({
          successUrl: `${process.env.GATSBY_SITE_URL}/dashboard-main`,
          failureUrl: `${process.env.GATSBY_SITE_URL}`,
          walletUrl:
            process.env.GATSBY_NETWORK_ID === "testnet"
              ? "https://wallet.testnet.near.org"
              : "https://wallet.near.org",
        }),
        setupNearWallet({
          successUrl: `${process.env.GATSBY_SITE_URL}/dashboard-main`,
          failureUrl: `${process.env.GATSBY_SITE_URL}`,
          walletUrl:
            process.env.GATSBY_NETWORK_ID === "testnet"
              ? "https://wallet.testnet.near.org"
              : "https://wallet.near.org",
        }),
        // setupSender(),
        setupHereWallet(),
        setupMathWallet(),
        setupNightly(),
        setupMeteorWallet(),
        setupNearSnap(),
        setupNarwallets(),
        setupWelldoneWallet(),
        setupLedger(),
        setupNearFi(),
        setupCoin98Wallet(),
        setupOptoWallet(),
        setupFinerWallet(),
        setupNeth(),
        setupXDEFI(),
        setupWalletConnect({
          projectId: "devshop",
          metadata: {
            name: "Devshop",
            description: "Find the best Web3 talents for your projects.",
            url: "https://github.com/near/wallet-selector",
            icons: [
              "https://www.devshopafrica.com/static/logo_main-a92b7ed11915029837a9fbf90b25280e.png",
            ],
          },
        }),
        setupNightlyConnect({
          url: "https://www.devshopafrica.com/",
          appMetadata: {
            additionalInfo: "",
            application: "Devshop Africa",
            description: "Find the best Web3 talents for your projects.",
            icon:
              "	https://www.devshopafrica.com/static/logo_main-a92b7ed11915029837a9fbf90b25280e.png",
          },
        }),
      ],
    });
    const _modal = setupModal(_selector, {
      contractId: process.env.GATSBY_CONTRACT_NAME,
      description: "Devshop Africa",
      methodNames: [
        "post_job",
        "feature_profile",
        "stake_mission",
        "complete_mission",
        "approve_mission",
        "fetch_jobs",
        "fetch_missions",
        "get_job_by_Id",
        "get_mission_by_id",
      ],
    });
    const state = _selector.store.getState();
    setAccounts(state.accounts);
    setSelector(_selector);
    setModal(_modal);
    setLoading(false);
  }, []);

  useEffect(() => {
    init()
      .then()
      .catch((err) => {
        console.error(err);
        alert("Failed to initialise wallet selector");
      });

  }, [init]);

  useEffect(() => {
    if (!selector) {
      return;
    }

    let accountId;
    let publicKey;

    const subscription = selector.store.observable
      .pipe(
        map((state) => state.accounts),
        distinctUntilChanged()
      )
      .subscribe((nextAccounts) => {
        setAccounts(nextAccounts);
        if (nextAccounts && nextAccounts[0]?.accountId) {
          accountId = nextAccounts[0].accountId;
          publicKey = nextAccounts[0].publicKey;
        }
      });

    const onHideSubscription = modal.on("onHide", async ({ hideReason }) => {

      if (selector.isSignedIn()) {
        localStorage.setItem("account_id", accountId);
        localStorage.setItem("public_key", publicKey);
        navigate("/dashboard-main");
      }
    });

    return () => {
      subscription.unsubscribe();
      onHideSubscription.remove();
    };
  }, [selector, modal]);

  const walletSelectorContextValue = useMemo(
    () => ({
      selector: selector,
      modal: modal,
      accounts,
      accountId: accounts.find((account) => account.active)?.accountId || null,
    }),
    [selector, modal, accounts]
  );

  if (loading) {
    return null;
  }

  return (
    <WalletSelectorContext.Provider value={walletSelectorContextValue}>
      {children}
    </WalletSelectorContext.Provider>
  );
};

export function useWalletSelector() {
  const context = useContext(WalletSelectorContext);

  if (!context) {
    throw new Error(
      "useWalletSelector must be used within a WalletSelectorContextProvider"
    );
  }

  return context;
}
