import React, { useContext } from "react";
import { Link } from "gatsby";
import GlobalContext from "../../context/GlobalContext";
import Logo from "../Logo";
import imgM from "../../assets/image/l1/png/message.png";
import NearIcon from "../../assets/image/svg/near_icon.svg";
import NearIconWhite from "../../assets/image/svg/near_icon_wht.svg";
import { login } from "../../utils/utilities";

const Footer = () => {
  const gContext = useContext(GlobalContext);
  return (
    <>
      <footer className="footer bg-ebony-clay dark-mode-texts">
        <div className="container">
          {/* <!-- Cta section --> */}
          <div className="pt-11 pt-lg-20 pb-13 pb-lg-20 border-bottom border-width-1 border-default-color-2">
            <div className="row justify-content-center ">
              <div
                className="col-xl-7 col-lg-12"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                {/* <!-- cta-content start --> */}
                <div className="pb-xl-0 pb-9 text-xl-left text-center">
                  <h2 className="text-white font-size-8 mb-4">
                    Connecting Africa's top talents with the world's leading
                    companies
                  </h2>
                  {/* <p className="text-hit-gray font-size-5 mb-0">
                    Connecting Africa's top talent with the world's leading companies
                  </p> */}
                </div>
                {/* <!-- cta-content end --> */}
              </div>
              <div
                className="col-xl-5 col-lg-12"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                {/* <!-- cta-btns start --> */}
                <div className="btns d-flex justify-content-xl-end justify-content-center align-items-xl-center flex-wrap h-100  mx-n4">
                  <button
                    className="btn btn-outline-gallery btn-xl mx-4 mt-6 text-uppercase d-flex near-login-btn-footer"
                    type="link"
                    onClick={(e) => {
                      e.preventDefault();
                      login(window.location.pathname, "/dashboard-main");
                    }}
                  >
                    <span className="">Login with</span>
                    <img
                      src={NearIconWhite}
                      alt="near icon"
                      height={34}
                      className="near-login-icon-white"
                    />
                    <img
                      src={NearIcon}
                      alt="near icon"
                      height={34}
                      className="near-login-icon"
                    />
                  </button>
                </div>
                {/* <!-- cta-btns end --> */}
              </div>
            </div>
          </div>
        </div>
        <div className="container  pt-12 pt-lg-19 pb-10 pb-lg-19">
          <div className="row">
            <div className="col-lg-5 col-sm-6 mb-lg-0 mb-9">
              <Logo white className="footer-logo mb-7" />
              <div className="media mb-11">
                <img src={imgM} className="align-self-center mr-3" alt="" />
                <div className="media-body pl-5">
                  <p className="mb-0 font-size-4 text-white">Email us at</p>
                  <a
                    className="mb-0 font-size-4 font-weight-bold"
                    href="mailto:info@sankore2.com"
                    rel="noreferrer"
                  >
                    info@devshopafrica.com
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-3 col-xs-6 text-center text-sm-left">
                  <div className="footer-widget widget2 mb-md-0 mb-13">
                    <p className="widget-title font-size-4 text-gray mb-md-8 mb-7">
                      Company
                    </p>
                    <ul className="widget-links pl-0 list-unstyled list-hover-primary">
                      <li className="mb-6">
                        <Link
                          to="/#"
                          onClick={(e) => e.preventDefault()}
                          className="heading-default-color font-size-4 font-weight-normal"
                        >
                          About us
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/blog-posts"
                          className="heading-default-color font-size-4 font-weight-normal"
                        >
                          Blog
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/contact-us"
                          className="heading-default-color font-size-4 font-weight-normal"
                        >
                          Contact us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-3 col-xs-6 text-center text-sm-left">
                  <div className="footer-widget widget2 mb-md-0 mb-13">
                    <p className="widget-title font-size-4 text-gray mb-md-8 mb-7">
                      Follow us
                    </p>
                    <div className="social-icons">
                      <ul className="pl-0 list-unstyled d-flex align-items-end justify-content-center justify-content-sm-start">
                        <li className="d-flex flex-column justify-content-center px-3 mr-3">
                          <a
                            href="https://www.instagram.com/devshopafrica/"
                            target="_blank"
                            rel="noreferrer"
                            className="hover-color-primary heading-default-color"
                          >
                            <i className="fab fa-instagram font-size-6 pt-2"></i>
                          </a>
                        </li>
                        <li className="d-flex flex-column justify-content-center px-3 mr-3">
                          <a
                            href="https://twitter.com/DevshopAfrica"
                            target="_blank"
                            rel="noreferrer"
                            className="hover-color-primary heading-default-color"
                          >
                            <i className="fab fa-twitter font-size-6 pt-2"></i>
                          </a>
                        </li>
                        <li className="d-flex flex-column justify-content-center px-3 mr-3">
                          <a
                            href="https://web.facebook.com/profile.php?id=100090092552835"
                            target="_blank"
                            rel="noreferrer"
                            className="hover-color-primary heading-default-color"
                          >
                            <i className="fab fa-facebook font-size-6 pt-2"></i>
                          </a>
                        </li>
                        <li className="d-flex flex-column justify-content-center px-3 mr-3">
                          <a
                            href="https://www.linkedin.com/company/devshopafrica/"
                            target="_blank"
                            className="hover-color-primary heading-default-color"
                          >
                            <i className="fab fa-linkedin-in font-size-6 pt-2"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
