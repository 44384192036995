import React from "react";
import { GlobalProvider } from "./src/context/GlobalContext";
import { CartProvider } from "./src/context/CartContext";
import { DataProvider } from "./src/context/DataContext";
import { AuthProvider } from "./src/context/AuthContext";
import { WalletSelectorContextProvider } from "./src/context/WalletSelectorContext";
import Layout from "./src/components/Layout";

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => (
  <GlobalProvider>
    <WalletSelectorContextProvider>
      <AuthProvider>
        <DataProvider>
          <CartProvider>{element}</CartProvider>
        </DataProvider>
      </AuthProvider>
    </WalletSelectorContextProvider>
  </GlobalProvider>
);
