const dataReducer = (state, action) => {
  switch (action.type) {
    case "SAVE_JOBS":
      const jobsByFeatured = action.payload.sort((a, b) => b.featured - a.featured);
      return {
        ...state,
        jobs: jobsByFeatured,
      };
    case "SAVE_TALENTS":
      const sortByFeatured = action.payload.sort((a, b) => b.featured - a.featured);
      return {
        ...state,
        talents: sortByFeatured,
      };
    case "GET_TALENT_DETAILS":
      return {
        ...state,
        talent: action.payload,
      };
    case "GET_EXPERTS":
      const experts = state.talents.filter(
        (talent) => talent.account_id !== action.payload
      );
      return {
        ...state,
        experts: experts.slice(0, 5),
      };
    case "GET_SAVED_TALENTS":
      return {
        ...state,
        savedTalents: action.payload,
      };
    case "SAVE_CLIENTS":
      return {
        ...state,
        clients: action.payload,
      };
    case "GET_REVENUE_SETTINGS":
      return {
        ...state,
        postJobFee: action.payload?.postJobFee || 0,
        commissionRate: action.payload?.commissionRate || 0,
        talentRate: action.payload?.talentRate || 0,
        featureProfileFee: action.payload?.featureProfileFee || 0,
      };
    case "SAVE_CONTRACTS":
      return {
        ...state,
        contracts: action.payload,
      };
    case "SAVE_CATEGORIES":
      return {
        ...state,
        categories: action.payload,
      };
    case "SAVE_UNREAD_CONTRACTS":
      return {
        ...state,
        unreadContracts: action.payload,
      };
    case "SAVE_USER_STATS":
      return {
        ...state,
        userStats: action.payload,
      };
    case "SAVE_SKILLS":
      const data = state.categories;
      if (data && data.length > 0 && data[0]?.subcategories) {
        let skills = [];
        data.map(({ subcategories }) => {
          subcategories.forEach((e) => {
            skills.push(e);
          });
        });
        return {
          ...state,
          allSkills: skills,
        };
      } else {
        return {
          ...state,
          allSkills: [],
        };
      }
    case "GET_CLIENT_DETAILS":
      return {
        ...state,
        client: action.payload,
      };
    default:
      throw new Error();
  }
};

export default dataReducer;
