exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-work-experience-js": () => import("./../../../src/pages/add-work-experience.js" /* webpackChunkName: "component---src-pages-add-work-experience-js" */),
  "component---src-pages-admin-edit-client-js": () => import("./../../../src/pages/admin-edit-client/[...].js" /* webpackChunkName: "component---src-pages-admin-edit-client-js" */),
  "component---src-pages-auth-js": () => import("./../../../src/pages/auth.js" /* webpackChunkName: "component---src-pages-auth-js" */),
  "component---src-pages-blog-edit-js": () => import("./../../../src/pages/blog-edit/[...].js" /* webpackChunkName: "component---src-pages-blog-edit-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog/[...].js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-posts-js": () => import("./../../../src/pages/blog-posts.js" /* webpackChunkName: "component---src-pages-blog-posts-js" */),
  "component---src-pages-candidate-profile-js": () => import("./../../../src/pages/candidate-profile/[...].js" /* webpackChunkName: "component---src-pages-candidate-profile-js" */),
  "component---src-pages-client-profile-js": () => import("./../../../src/pages/client-profile/[...].js" /* webpackChunkName: "component---src-pages-client-profile-js" */),
  "component---src-pages-companies-js": () => import("./../../../src/pages/companies.js" /* webpackChunkName: "component---src-pages-companies-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-contract-details-js": () => import("./../../../src/pages/contract-details/[...].js" /* webpackChunkName: "component---src-pages-contract-details-js" */),
  "component---src-pages-contract-signing-js": () => import("./../../../src/pages/contract-signing.js" /* webpackChunkName: "component---src-pages-contract-signing-js" */),
  "component---src-pages-dashboard-admin-clients-js": () => import("./../../../src/pages/dashboard-admin-clients.js" /* webpackChunkName: "component---src-pages-dashboard-admin-clients-js" */),
  "component---src-pages-dashboard-admin-contracts-js": () => import("./../../../src/pages/dashboard-admin-contracts.js" /* webpackChunkName: "component---src-pages-dashboard-admin-contracts-js" */),
  "component---src-pages-dashboard-admin-create-client-js": () => import("./../../../src/pages/dashboard-admin-create-client.js" /* webpackChunkName: "component---src-pages-dashboard-admin-create-client-js" */),
  "component---src-pages-dashboard-admin-job-details-js": () => import("./../../../src/pages/dashboard-admin-job-details/[...].js" /* webpackChunkName: "component---src-pages-dashboard-admin-job-details-js" */),
  "component---src-pages-dashboard-admin-jobs-js": () => import("./../../../src/pages/dashboard-admin-jobs.js" /* webpackChunkName: "component---src-pages-dashboard-admin-jobs-js" */),
  "component---src-pages-dashboard-admin-js": () => import("./../../../src/pages/dashboard-admin.js" /* webpackChunkName: "component---src-pages-dashboard-admin-js" */),
  "component---src-pages-dashboard-admin-settings-js": () => import("./../../../src/pages/dashboard-admin-settings.js" /* webpackChunkName: "component---src-pages-dashboard-admin-settings-js" */),
  "component---src-pages-dashboard-admin-talents-js": () => import("./../../../src/pages/dashboard-admin-talents.js" /* webpackChunkName: "component---src-pages-dashboard-admin-talents-js" */),
  "component---src-pages-dashboard-admin-wallet-js": () => import("./../../../src/pages/dashboard-admin-wallet.js" /* webpackChunkName: "component---src-pages-dashboard-admin-wallet-js" */),
  "component---src-pages-dashboard-contracts-js": () => import("./../../../src/pages/dashboard-contracts.js" /* webpackChunkName: "component---src-pages-dashboard-contracts-js" */),
  "component---src-pages-dashboard-help-js": () => import("./../../../src/pages/dashboard-help.js" /* webpackChunkName: "component---src-pages-dashboard-help-js" */),
  "component---src-pages-dashboard-jobs-js": () => import("./../../../src/pages/dashboard-jobs.js" /* webpackChunkName: "component---src-pages-dashboard-jobs-js" */),
  "component---src-pages-dashboard-main-js": () => import("./../../../src/pages/dashboard-main.js" /* webpackChunkName: "component---src-pages-dashboard-main-js" */),
  "component---src-pages-dashboard-settings-js": () => import("./../../../src/pages/dashboard-settings.js" /* webpackChunkName: "component---src-pages-dashboard-settings-js" */),
  "component---src-pages-dashboard-talents-js": () => import("./../../../src/pages/dashboard-talents.js" /* webpackChunkName: "component---src-pages-dashboard-talents-js" */),
  "component---src-pages-dashboard-wallet-js": () => import("./../../../src/pages/dashboard-wallet.js" /* webpackChunkName: "component---src-pages-dashboard-wallet-js" */),
  "component---src-pages-dashoard-profile-js": () => import("./../../../src/pages/dashoard-profile.js" /* webpackChunkName: "component---src-pages-dashoard-profile-js" */),
  "component---src-pages-edit-profile-js": () => import("./../../../src/pages/edit-profile.js" /* webpackChunkName: "component---src-pages-edit-profile-js" */),
  "component---src-pages-edit-work-experience-js": () => import("./../../../src/pages/edit-work-experience.js" /* webpackChunkName: "component---src-pages-edit-work-experience-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hire-talent-js": () => import("./../../../src/pages/hire-talent/[...].js" /* webpackChunkName: "component---src-pages-hire-talent-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-details-edit-js": () => import("./../../../src/pages/job-details-edit/[...].js" /* webpackChunkName: "component---src-pages-job-details-edit-js" */),
  "component---src-pages-job-details-js": () => import("./../../../src/pages/job-details/[...].js" /* webpackChunkName: "component---src-pages-job-details-js" */),
  "component---src-pages-new-blog-js": () => import("./../../../src/pages/new-blog.js" /* webpackChunkName: "component---src-pages-new-blog-js" */),
  "component---src-pages-post-job-js": () => import("./../../../src/pages/post-job.js" /* webpackChunkName: "component---src-pages-post-job-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-profile-update-js": () => import("./../../../src/pages/profile-update.js" /* webpackChunkName: "component---src-pages-profile-update-js" */),
  "component---src-pages-search-list-2-js": () => import("./../../../src/pages/search-list-2.js" /* webpackChunkName: "component---src-pages-search-list-2-js" */),
  "component---src-pages-search-list-js": () => import("./../../../src/pages/search-list.js" /* webpackChunkName: "component---src-pages-search-list-js" */),
  "component---src-pages-talent-list-js": () => import("./../../../src/pages/talent-list.js" /* webpackChunkName: "component---src-pages-talent-list-js" */)
}

