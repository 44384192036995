import React, { createContext, useReducer } from "react";
import authReducer from "./reducers/authReducer";
import { postData, getAccount, createAccount, fetchUserAccount } from "../api";

const initialState = {
  // auth: JSON.parse(window.localStorage.getItem('auth')),
  auth: null,
  profileView: null,
};

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  async function getUser(accountId) {
    try {
      const response = await fetchUserAccount(accountId);
      if (response.length > 0) {
        if (
          (response[0].data()?.client &&
            response[0].data()?.client?.status === "disabled") ||
          (response[0].data()?.talent &&
            response[0].data()?.talent?.status === "disabled")
        ) {
          throw new Error(
            "Your account has been disabled. Please contact the administrator"
          );
        } else {
          localStorage.setItem("auth", JSON.stringify(response[0].data()));
          dispatch({
            type: "LOGIN_USER",
            payload: response[0].data(),
          });
          return response[0].data();
        }
      } else {
        return false;
      }
    } catch (error) {
      // console.log(error.message);
      return error;
    }
  }

  async function logoutUser() {
    dispatch({
      type: "LOGOUT_USER",
    });
    localStorage.clear();
    sessionStorage.clear();
  }

  async function isAuthenticated() {
    // console.log("auth::::: ");
    let user = null;
    const auth = localStorage.getItem("auth");
    // console.log("auth: ", auth);
    if (auth) {
      user = JSON.parse(auth);
      dispatch({
        type: "LOGIN_USER",
        payload: user,
      });
    }
    return user;
  }

  return (
    <AuthContext.Provider
      value={{
        auth: state.auth,
        profileView: state.profileView,
        getUser,
        logoutUser,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
