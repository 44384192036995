import React, { useContext } from "react";
import { Link } from "gatsby";
import { Collapse } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { AuthContext } from "../../context/AuthContext";
import { DataContext } from "../../context/DataContext";
import imgL from "../../assets/image/logo_main.png";

const Sidebar = (props) => {
  const gContext = useContext(GlobalContext);
  const { auth, profileView } = useContext(AuthContext);
  const { unreadContracts } = useContext(DataContext);
  const pathname = typeof window !== 'undefined' ? window.location.pathname : '';
  const active = pathname.includes("dashboard-main") ? "active" : "";

  return (
    <>
      <Collapse in={gContext.showSidebarDashboard}>
        <div className="dashboard-sidebar-wrapper pt-11" id="sidebar">
          <div className="brand-logo px-11">
            <Link to="/">
              <img src={imgL} alt="Devshop logo" height={45} />
            </Link>
          </div>
          {profileView === "Client" ? (
            <div className="my-15 px-11">
              <Link
                to="/post-job"
                className="btn btn-primary btn-medium w-100 text-uppercase focus-reset"
              >
                <span className="mr-5 d-inline-block">+</span>Post a new job
              </Link>
            </div>
          ) : null}
          <ul className="list-unstyled dashboard-layout-sidebar">
            <li className="">
              <Link
                activeClassName="active"
                to="/dashboard-main"
                className={`px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center text-uppercase ${active}`}
              >
                <i className="icon icon-layout-11 mr-7"></i>{profileView !== "Admin" ? "My Profile" : "Overview"}
              </Link>
            </li>
            <li className="">
              <Link
                to={profileView === "Admin" ? "/dashboard-admin-jobs" : "/dashboard-jobs"}
                activeClassName="active"
                className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center text-uppercase"
              >
                <i className="fas fa-briefcase mr-7"></i>
                {profileView !== "Admin" ? (
                  <span>My Jobs</span>
                ) : (
                  <span>Jobs</span>
                )}
              </Link>
            </li>
            <li className="">
              <Link
                to={profileView === "Admin" ? "/dashboard-admin-contracts" : "/dashboard-contracts"}
                activeClassName="active"
                className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center text-uppercase"
              >
                <i className="fas fa-file-contract mr-7"></i>
                {profileView === "Admin" ? (
                  <span>Contracts</span>
                ) : auth?.talent ? (
                  <div className="d-flex align-items-center">
                    <span>My Contracts</span>
                    {unreadContracts ? (
                      <i className="fas fa-circle text-red-2 font-size-2 ml-7"></i>
                    ) : null}
                  </div>
                ) : <span>My Contracts</span>}
              </Link>
            </li>
            {profileView === "Admin" ? (
              <li className="">
                <Link
                  to="/dashboard-admin-clients"
                  activeClassName="active"
                  className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center text-uppercase"
                >
                  <i className="fas fa-users mr-7"></i>Clients
                </Link>
              </li>
            ) : null}
            {profileView !== "Talent" ? (
              <li className="">
                <Link
                  to={profileView === "Client" ? "/dashboard-talents" : "/dashboard-admin-talents"}
                  activeClassName="active"
                  className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center text-uppercase"
                >
                  <i className="fas fa-users mr-7"></i>
                  {profileView === "Client" ? (
                    <span>My Talents</span>
                  ) : (
                    <span>Talents</span>
                  )}
                </Link>
              </li>
            ) : null}
            <li className="">
              <Link
                to={profileView === "Admin" ? "/dashboard-admin-wallet" : "/dashboard-wallet"}
                activeClassName="active"
                className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center text-uppercase"
              >
                <i className="fas fa-wallet mr-7"></i>My Wallet
              </Link>
            </li>
            {!auth?.admin ? (
              <li className="">
                <Link
                  to="/dashboard-help"
                  activeClassName="active"
                  className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center text-uppercase"
                >
                  <i className="fas fa-question-circle mr-7"></i>Help
                </Link>
              </li>
            ) : null}
            {auth?.admin ? (
              <li className="">
                <Link
                  to="/dashboard-help"
                  activeClassName="active"
                  className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center text-uppercase"
                >
                  <i className="fas fa-question-circle mr-7"></i>Disputes
                </Link>
              </li>
            ) : null}
            {profileView === "Admin" ? (
              <li className="">
                <Link
                  to="/dashboard-admin-settings"
                  activeClassName="active"
                  className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center text-uppercase"
                >
                  <i className="fas fa-cog mr-7"></i>Settings
                </Link>
              </li>
            ) : null}
          </ul>
        </div>
      </Collapse>
      <a
        href="/#"
        className="sidebar-mobile-button"
        onClick={(e) => {
          e.preventDefault();
          gContext.toggleSidebarDashboard();
        }}
      >
        <i className="icon icon-sidebar-2"></i>
      </a>
    </>
  );
};

export default Sidebar;
