import React, { createContext, useReducer } from "react";
import dataReducer from "./reducers/dataReducer";
import {
  createJob,
  fetchClientJobs,
  fetchAllJobs,
  fetchAllTalents,
  fetchUserAccount,
  fetchSavedTalents,
  updateJob,
  fetchAllClients,
  updateRevenueModel,
  fetchRevenueModel,
  fetchUserContracts,
  fetchAllContracts,
  fetchTalentJobs,
  addCategory,
  fetchCategories,
} from "../api";

const initialState = {
  jobs: [],
  talents: [],
  clients: [],
  talent: null,
  experts: [],
  contracts: [],
  savedTalents: [],
  categories: [],
  currencies: [
    {
      label: "NEAR",
      value: "near",
    },
    {
      label: "USDT",
      value: "usdt",
    },
    {
      label: "EURO",
      value: "euro",
    },
  ],
  jobTypes: [
    {
      label: "Full Time",
      value: "full-time",
    },
    {
      label: "Freelancing",
      value: "freelancing",
    },
  ],
  experienceLevels: [
    { label: "Junior", value: "junior" },
    { label: "Mid-level", value: "level" },
    { label: "Senior", value: "senior" },
  ],
  englishProficiencyLevels: [
    { label: "Beginner", value: "beginner" },
    { label: "Advanced", value: "advanced" },
    { label: "Native", value: "native" },
  ],
  postJobFee: 0,
  commissionRate: 0,
  talentRate: 0,
  featureProfileFee: 0,
  unreadContracts: null,
  userStats: null,
  allSkills: [],
  client: null,
  industries: [
    {
      label: "Agriculture, Forestry, Fishing or Hunting",
      value: "Agriculture, Forestry, Fishing or Hunting",
    },
    { label: "Mining", value: "Mining" },
    { label: "Construction", value: "Construction" },
    { label: "Manufacturing", value: "Manufacturing" },
    { label: "Wholesale Trade", value: "Wholesale Trade" },
    { label: "Retail Trade", value: "Retail Trade" },
    {
      label: "Transportation and Warehousing",
      value: "Transportation and Warehousing",
    },
    { label: "Information Technology", value: "Information Technology" },
    { label: "Finance and Insurance", value: "Finance and Insurance" },
    { label: "Real Estate", value: "Real Estate" },
    { label: "Education", value: "Education" },
    {
      label: "Health Care and Social Assistance",
      value: "Health Care and Social Assistance",
    },
    {
      label: "Arts, Entertainment, and Recreation",
      value: "Arts, Entertainment, and Recreation",
    },
    {
      label: "Accommodation and Food Services",
      value: "Accommodation and Food Services",
    },
    { label: "Public Administration", value: "Public Administration" },
    { label: "Other", value: "Other" },
  ],
};

export const DataContext = createContext(initialState);

export const DataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(dataReducer, initialState);

  async function postJob(accountId, data) {
    const response = await createJob(accountId, data);
    return response;
  }

  async function getjobsList(accountId) {
    const response = await fetchClientJobs(accountId);
    dispatch({
      type: "SAVE_JOBS",
      payload: response,
    });
    return response;
  }

  async function getAllJobsList() {
    const response = await fetchAllJobs();
    if (response.length > 0) {
      dispatch({
        type: "SAVE_JOBS",
        payload: response,
      });
    }
    return response;
  }

  async function getAllTalentList() {
    const response = await fetchAllTalents();
    if (response.length > 0) {
      dispatch({
        type: "SAVE_TALENTS",
        payload: response,
      });
    }
    return response;
  }

  async function getTalentDetails(account_id) {
    const response = await fetchUserAccount(account_id);
    if (response.length > 0) {
      dispatch({
        type: "GET_TALENT_DETAILS",
        payload: response[0].data().talent,
      });
      dispatch({
        type: "GET_EXPERTS",
        payload: account_id,
      });
    }
    return response[0].data();
  }

  async function getSavedTalents(account_id) {
    const response = await fetchSavedTalents(account_id);
    if (response && response.talents) {
      dispatch({
        type: "GET_SAVED_TALENTS",
        payload: response.talents,
      });
    }
    return response;
  }

  async function searchTalentsByTitle() {
    const response = await fetchAllJobs();
    if (response.length > 0) {
      dispatch({
        type: "SAVE_JOBS",
        payload: response,
      });
    }
    return response;
  }

  async function placeBid(jobId, updateType, data) {
    try {
      const response = await updateJob(jobId, updateType, data);
      if (response !== true) {
        throw new Error("You have already applied to this job");
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }

  async function getAllClientList() {
    let data = [];
    const response = await fetchAllClients();
    if (response.length > 0) {
      for (const client of response) {
        let jobs = await fetchClientJobs(client.account_id);
        data.push({
          jobs,
          ...client,
        });
      }

      dispatch({
        type: "SAVE_CLIENTS",
        payload: data,
      });
    }
    return data;
  }

  async function setRevenueModel(data) {
    try {
      const response = await updateRevenueModel(data);
      return response;
    } catch (error) {
      console.log(error.message);
      return error;
    }
  }

  async function getRevenueModel() {
    try {
      const response = await fetchRevenueModel();
      dispatch({
        type: "GET_REVENUE_SETTINGS",
        payload: response,
      });
      return response;
    } catch (error) {
      console.log(error.message);
      return error;
    }
  }

  async function getContratcsList(accountId, accountType) {
    const response = await fetchUserContracts(accountId, accountType);
    if (response.length > 0) {
      dispatch({
        type: "SAVE_CONTRACTS",
        payload: response,
      });
    }
    return response;
  }

  async function getAllContractsList() {
    const response = await fetchAllContracts();
    if (response.length > 0) {
      dispatch({
        type: "SAVE_CONTRACTS",
        payload: response,
      });
    }
    return response;
  }

  async function getTalentJobsList(accountId) {
    const response = await fetchTalentJobs(accountId);
    dispatch({
      type: "SAVE_JOBS",
      payload: response,
    });
    return response;
  }

  async function setCategories(data) {
    try {
      const response = await addCategory(data);
      return response;
    } catch (error) {
      console.log(error.message);
      return error;
    }
  }

  async function getCategories(data) {
    try {
      const response = await fetchCategories();
      dispatch({
        type: "SAVE_CATEGORIES",
        payload: response,
      });
    } catch (error) {
      console.log(error.message);
      return error;
    }
  }

  async function setUnreadContracts(count) {
    try {
      dispatch({
        type: "SAVE_UNREAD_CONTRACTS",
        payload: count,
      });
    } catch (error) {
      console.log(error.message);
      return error;
    }
  }

  async function setUserStats(data) {
    try {
      dispatch({
        type: "SAVE_USER_STATS",
        payload: data,
      });
    } catch (error) {
      console.log(error.message);
      return error;
    }
  }

  async function getSkills() {
    try {
      dispatch({
        type: "SAVE_SKILLS",
      });
    } catch (error) {
      console.log(error.message);
      return error;
    }
  }

  async function getClientDetails(account_id) {
    const response = await fetchUserAccount(account_id);
    if (response.length > 0) {
      dispatch({
        type: "GET_CLIENT_DETAILS",
        payload: response[0].data()?.client || response[0].data()?.admin,
      });
    }
    return response[0].data();
  }

  return (
    <DataContext.Provider
      value={{
        jobs: state.jobs,
        talents: state.talents,
        clients: state.clients,
        talent: state.talent,
        experts: state.experts,
        contracts: state.contracts,
        savedTalents: state.savedTalents,
        categories: state.categories,
        currencies: state.currencies,
        jobTypes: state.jobTypes,
        experienceLevels: state.experienceLevels,
        englishProficiencyLevels: state.englishProficiencyLevels,
        postJobFee: state.postJobFee,
        commissionRate: state.commissionRate,
        talentRate: state.talentRate,
        featureProfileFee: state.featureProfileFee,
        unreadContracts: state.unreadContracts,
        userStats: state.userStats,
        allSkills: state.allSkills,
        client: state.client,
        imageUrl: state.imageUrl,
        industries: state.industries,
        postJob,
        getjobsList,
        getAllJobsList,
        getAllTalentList,
        getTalentDetails,
        getSavedTalents,
        placeBid,
        getAllClientList,
        setRevenueModel,
        getRevenueModel,
        getContratcsList,
        getAllContractsList,
        getTalentJobsList,
        setCategories,
        getCategories,
        setUnreadContracts,
        setUserStats,
        getSkills,
        getClientDetails,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
