import toast from "react-hot-toast";

const notify = (message, type) => type && type === "success" ?
  toast.success(message, {
    duration: 3000,
    position: "bottom-left",
    style: {
      fontSize: "0.75rem",
    }
  }) : toast.error(message, {
    duration: 3000,
    position: "bottom-left",
    style: {
      fontSize: "0.75rem",
    }
  });

export default notify;
